<template>
    <div id="app">
        <div class="loaderIcon" v-if="!loaded"></div>
        <div v-else>
            <IntroSection :imageUrl="introImageUrl"
                          :titlePrefix="titlePrefix"
                          :title="title"
                          :body="body"></IntroSection>
            <vue-picture-swipe class="gallery" :items="items"></vue-picture-swipe>
        </div>
    </div>
</template>

<script>
    import IntroSection from './IntroSection'
    import VuePictureSwipe from 'vue-picture-swipe'

    export default {
        name: 'app',
        components: {
            IntroSection,
            'vue-picture-swipe': VuePictureSwipe,
        },
        props: {
            categoryJsonUrl : String
        },
        created: function () {
            this.reloadJson();
        },
        watch: {
            categoryJsonUrl: function(newVal, oldVal) { // watch it
                this._data.loaded = false;
                this._data.items = [];
                this._data.introImageUrl = "";
                this._data.titlePrefix = "";
                this._data.title = "";
                this._data.makeupType = "";
                this._data.body = "";
                this.reloadJson();
            }
        },
        methods: {
            reloadJson: function() {
                fetch(this._props.categoryJsonUrl).then((response)=>response.json()).then((json)=> {
                    this._data.items = json.items;
                    this._data.introImageUrl = json.introImageUrl;
                    this._data.titlePrefix = json.titlePrefix;
                    this._data.title = json.title;
                    this._data.makeupType = json.makeupType;
                    this._data.body = json.body;
                    this._data.loaded = true;
                });
            }
        },
        data() {
            return {
                items: [],
                introImageUrl: "",
                titlePrefix: "",
                title: "",
                body: "",
                makeupType: "איפור כלות",
                loaded: false
            }
        }
    }
</script>

<style>
    #app {
        font-family: 'Varela Round', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        direction: rtl;
    }

    .spad {
        padding-top: 60px;
        padding-bottom: 115px;
    }

    .intro-text {
        padding-right: 30px;
    }
</style>
