import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App'
import StandardPage from './components/StandardPage'
import LoadScript from 'vue-plugin-load-script';
import { VLazyImagePlugin } from 'v-lazy-image';


Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(LoadScript);
Vue.use(VLazyImagePlugin);
Vue.loadScript("js/main.js");

const routes = [
    { path: '/', redirect: '/index.html' },
    { path: '/gallery.html', component: StandardPage, props: {"currentTab" : "gallery.html" }},
    { path: '/index.html', component: StandardPage, props: {"currentTab" : "index.html"} },
    { path: '/bride.html', component: StandardPage, props: {"currentTab" : "bride.html" }},
    { path: '/evening.html', component: StandardPage, props: {"currentTab" : "evening.html" }},
    { path: '/makeup-and-hair.html', component: StandardPage, props: {"currentTab" : "makeup-and-hair.html" }},
    { path: '/purim.html', component: StandardPage, props: {"currentTab" : "purim.html" }},
    { path: '/ashdod.html', component: StandardPage, props: { "currentTab" : "ashdod.html" }},
    { path: '/ashdod2.html', component: StandardPage, props: { "currentTab" : "ashdod2.html" }},
];

export const router = new VueRouter({mode: 'history', routes: routes});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');