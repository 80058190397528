<template>
    <!-- Header section -->
	<header class="header-section">
		<!-- Google Tag Manager (noscript) -->
		<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WK5DGS"
		height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
		<!-- End Google Tag Manager (noscript) -->

		<nav class="navbar navbar-expand-md navbar-dark bg-dark site-navbar">
			<router-link class="navbar-brand site-logo" to="/index.html">
				<h2><span>יעל </span>תמם</h2>
				<p>איפור ושיער מקצועי</p>
			</router-link>
			<button class="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId"
				aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="collapsibleNavId">
				<!-- Main menu -->
				<ul class="navbar-nav ml-auto mt-2 mt-lg-0">
					<li class="nav-item">
                        <router-link class="nav-link" to="/index.html" @click="closeMenu">בית</router-link>
					</li>
					<li class="nav-item">
                        <router-link class="nav-link" to="/gallery.html" @click="closeMenu">גלריה</router-link>
					</li>
					<li class="nav-item">
                        <router-link class="nav-link" to="/bride.html" @click="closeMenu">איפור כלות</router-link>
					</li>
					<li class="nav-item">
                        <router-link class="nav-link" to="/evening.html" @click="closeMenu">איפור ערב</router-link>
					</li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/makeup-and-hair.html" @click="closeMenu">איפור ושיער</router-link>
                    </li>
					<li class="nav-item">
						<router-link class="nav-link" to="/purim.html" @click="closeMenu">איפור לפורים</router-link>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#contact">צור קשר</a>
					</li>                                                                
				</ul>
				<div class="social-links my-2 my-lg-0">
					<a href="tel:+972523426080"><i class="fa fa-phone"></i></a>
					<a href="https://www.instagram.com/yaeltamam/"><i class="fa fa-instagram"></i></a>
					<a href="http://tiny.cc/yaelmakeupfb"><i class="fa fa-facebook"></i></a>
					<a href="https://api.whatsapp.com/send?phone=972523426080"><i class="fa fa-whatsapp"></i></a>
					<a href="https://www.waze.com/ul?ll=31.76802240%2C34.63541000&navigate=yes"><i class="fa fa-car"></i></a>
				</div>
			</div>
		</nav>
	</header>
	<!-- Header section end-->
</template>
<script>
    export default {
        name: "SiteHeader",
        methods: {
            closeMenu : function() {
                // collapse menu if opened
                if ($("#collapsibleNavId").hasClass("show"))
                    $('.navbar-toggler').click();
			}
		}
    }
</script>
<style>
</style>