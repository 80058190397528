<template>
    <div>
        <!-- Page Preloder -->
        <div id="preloder">
            <div class="loader"></div>
        </div>
        <SiteHeader/>
        <keep-alive>
            <component :is="dynamicComponent" v-bind="currentProperties"></component>
        </keep-alive>

        <!-- Back to top -->
        <div class="container">
            <div class="backtotop">
                <div class="up-btn" id="backTotop">מעלה</div>
            </div>
        </div>

        <SiteFooter :makeupType="makeupType"/>
    </div>
</template>

<script>
    import Home from './HomePage'
    import Gallery from './GalleryPage'
    import Category from './CategoryPage'
    import SiteHeader from './SiteHeader'
    import SiteFooter from './SiteFooter'

    export default {
        props: {
            currentTab: String
        },
        data() {
            let tabs =  {
                "index.html" : {component: Home, makeupType: "איפור", props: false},
                "gallery.html" :{component: Gallery, makeupType: "איפור כלות", props: false},
                "bride.html": {component: Category, makeupType: "איפור כלות", props: {categoryJsonUrl: "json/bride.json"}},
                "evening.html" : {component:Category, makeupType: "איפור ערב", props: {categoryJsonUrl: "json/evening.json"}},
                "makeup-and-hair.html" : {component:Category, makeupType: "איפור ושיער", props: {categoryJsonUrl: "json/makeup_and_hair.json"}},
                "purim.html" : {component:Category, makeupType: "איפור אפקטים/תחפושת/סרטים", props: {categoryJsonUrl: "json/purim.json"}},
                "ashdod.html" : {component:Category, makeupType: "איפור ושיער", props: {categoryJsonUrl: "json/ashdod.json"}},
                "ashdod2.html" : {component:Category, makeupType: "איפור ושיער", props: {categoryJsonUrl: "json/ashdod2.json"}}
            };

            return {
                pages: tabs,
                makeupType: tabs[this._props.currentTab].makeupType
            }
        },
        components: {
            SiteHeader,
            SiteFooter
        },
        watch:{
            $route (to) {
                if (to.hash === "") {
                    let pageName = to.path.replace("/", "")
                    if (this.pages[pageName]) {
                        // collapse header menu if opened
                        if ($("#collapsibleNavId").hasClass("show"))
                            $('.navbar-toggler').click();

                        // scroll up
                        $("html, body").animate({scrollTop: 0}, 100);

                        // update makeup type on "contact" section
                        this._data.makeupType = this.pages[pageName].makeupType;
                    }
                } else {
                    return false;
                }
            }
        },
        computed: {
            dynamicComponent() {
                let page = this.pages[this._props.currentTab];
                if (page)
                    return page.component;
                else
                    return Home
            },
            currentProperties: function() {
                let page = this.pages[this._props.currentTab];
                if (page)
                    return page.props;
                else
                    return this.pages["home"].props;
            }
        }
    }
</script>