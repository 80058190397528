<template>
    <div id="app">
        <vue-picture-swipe class="gallery" :items="items"></vue-picture-swipe>
        <div v-if="!loaded" class="loaderIcon"></div>
        <div class="container" v-if="more">
            <div class="backtotop">
                <div class="more-btn" id="more" @click="fetchNext">עוד</div>
            </div>
        </div>
    </div>
</template>

<script>
    import VuePictureSwipe from 'vue-picture-swipe';
    let items = [];

    export default {
        name: 'app',
        components: {
            'vue-picture-swipe': VuePictureSwipe
        },
        data() {
            return {
                items: items,
                nextUrl: `https://yaeltamam.azurewebsites.net/api/gallery?code=vNpt8aWFIw6pIbKdYh2sRIai1nH9IdaOk3Ylv833lGElg6DzfJNg2g==`,
                more: false,
                loaded: false
            };
        },
        created: function () {
            this.fetchNext();
        },
        methods: {
            fetchNext() {
                let data = this._data;
                data.loaded = false;
                fetch(data.nextUrl).then((response)=> {
                    if (response.ok) {
                        response.json().then((json)=> {
                            for(let imageIndex in json.data) {
                                let image = json.data[imageIndex];
                                if (image.media_type == "IMAGE") {
                                    let item = {
                                        src: image.media_url,
                                        thumbnail : `https://yaeltamam.azurewebsites.net/api/makeThumb?code=qqepFNyZWBS14JloFyUSNrfme26rUjdSbABfDPL0Sghm03EUgcXNFw==&url=${encodeURIComponent(image.media_url)}`,
                                        w: 1024,
                                        h: 1024,
                                        alt: image.caption
                                    };
                                    items.push(item);
                                } else if (image.media_type == "CAROUSEL_ALBUM") {
                                    for(let childIndex in image.children.data) {
                                        let childImage = image.children.data[childIndex];
                                        if (childImage.media_type == "IMAGE") {
                                            let item = {
                                                src: childImage.media_url,
                                                thumbnail : `https://yaeltamam.azurewebsites.net/api/makeThumb?code=qqepFNyZWBS14JloFyUSNrfme26rUjdSbABfDPL0Sghm03EUgcXNFw==&url=${encodeURIComponent(childImage.media_url)}`,
                                                w: 1024,
                                                h: 1024
                                            };
                                            items.push(item);
                                        }
                                    }
                                }
                            }
                            if (json.paging.next) {
                                data.nextUrl = json.paging.next;
                                data.more = true;
                            } else {
                                data.more = false;
                            }
                            data.loaded = true;
                        })
                    } else {
                        console.log("Failed - " + response.status);
                    }
                }).catch((reason)=> {
                    console.log("Error loading photos from instagram - " + reason);
                });
            }
        }
    }

</script>

<style>
    #app {
        font-family: 'Varela Round', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        direction: rtl;
    }

    .portfolio-section {
        margin-right: 32px;
        margin-left: 32px;
    }

    .gallery {
        margin: auto;
        width: 100%;
        padding: 0px;
    }

    .my-gallery {
        display: flex;
        flex-flow: wrap;
        justify-content: space-evenly;
    }

    /* animation for each photo added to gallery */
    @keyframes FadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .my-gallery img:nth-child(n) {
        opacity: 0;
        animation: FadeIn 0.5s linear;
        animation-fill-mode: forwards;
    }

    img.pswp__img {
        object-fit: cover; /* Do not scale the image */
        object-position: center; /* Center the image within the element */
    }

    figure {
        width: 160px;
    }
</style>