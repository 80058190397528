<template>
  <div>
  <HeroSection/>
  <IntroSection imageUrl="img/intro-img.jpg"
                titlePrefix="אפלטון"
                title='"אהבה ליופי, כמו אהבה לטוב, לא צריכה הסבר."'
                body="מאפרת מקצועית ברמה גבוהה, מתמחה באיפור כלות ומלוות, איפור ערב, תסרוקות והעברת סדנאות לאיפור אישי. אני כאן כדי לתת לך את האיפור המתאים ביותר עבורך, ביחס אישי, מקצועיות ודיוק הנובעים מאהבת המקצוע ועל מנת שתהיי מרוצה."/>
  <PortfolioSection/>
  </div>
</template>

<script>
import HeroSection from './HeroSection'
import IntroSection from './IntroSection'
import PortfolioSection from './PortfolioSection'

export default {
  name: 'app',
  components: {
    HeroSection,
    IntroSection,
    PortfolioSection
  }
}

</script>

<style>
#app {
  font-family: 'Varela Round', sans-serif;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	direction: rtl;
}
</style>
