<template>
    <!-- Intro section -->
	<section class="intro-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-5">
					<v-lazy-image :src="imageUrl" class="intro" alt=""/>
				</div>
				<div class="col-lg-7 intro-text">
					<span>{{titlePrefix}}</span>
					<h1>{{title}}</h1>
					<p v-html="body"></p>
					<!--<a href="#" class="site-btn sb-dark">קרא עוד <i class="fa fa-angle-double-left"></i></a>-->
					<a href="#contact"><button class="site-btn sb-dark">צרי קשר <i class="fa fa-angle-double-left"></i></button></a>
				</div>
			</div>
		</div>
	</section>
	<!-- Intro section end-->
</template>
<script>
    import VLazyImage from "v-lazy-image";

    export default {
        name: 'IntroPage',
		props: {
            titlePrefix : String,
			title: String,
			body: String,
			imageUrl: String
		},
		components: {
            VLazyImage
        }
	}
</script>

<style scoped>
	.v-lazy-image {
		opacity: 0;
		transition: opacity 2s;
	}
	.v-lazy-image-loaded {
		opacity: 1;
	}
</style>