<template>
    <!-- Portfolio section -->
	<section class="portfolio-section">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-md-7">
					<router-link to="/makeup-and-hair.html" class="portfolio-item">
						<v-lazy-image src="img/portfolio/1.jpg" alt=""/>
						<h4>איפור ושיער</h4>
					</router-link>
				</div>
				<div class="col-lg-3 col-md-5">
					<router-link to="/evening.html" class="portfolio-item">
						<v-lazy-image src="img/portfolio/2.jpg" alt=""/>
						<h4>איפור ערב</h4>
					</router-link>
				</div>
				<div class="col-lg-5 col-md-12">
					<router-link to="/makeup-and-hair.html" class="portfolio-item">
						<v-lazy-image src="img/portfolio/3.jpg" alt=""/>
						<h4>איפור אופנה</h4>
					</router-link>
				</div>
				<div class="col-lg-6 col-md-12">
					<router-link to="/makeup-and-hair.html" class="portfolio-item">
						<v-lazy-image src="img/portfolio/4.jpg" alt=""/>
						<h4>איפור ושיער</h4>
					</router-link>
				</div>
				<div class="col-lg-3 col-sm-6">
					<router-link to="/evening.html" class="portfolio-item">
						<v-lazy-image src="img/portfolio/5.jpg" alt=""/>
						<h4>איפור לנשף</h4>
					</router-link>
					<router-link to="/evening" class="portfolio-item">
						<v-lazy-image src="img/portfolio/7.jpg" alt=""/>
						<h4>איפור ערב</h4>
					</router-link>
				</div>
				<div class="col-lg-3 col-sm-6">
					<router-link to="/evening.html" class="portfolio-item">
						<v-lazy-image src="img/portfolio/6.jpg" alt=""/>
						<h4>איפור ערב</h4>
					</router-link>
					<router-link to="/evening.html" class="portfolio-item">
						<v-lazy-image src="img/portfolio/8.jpg" alt=""/>
						<h4>איפור ערב</h4>
					</router-link>
				</div>
			</div>
		</div>
	</section>
</template>

<!-- Portfolio section end-->
<script>
	import VLazyImage from "v-lazy-image";

	export default {
		components: {
			VLazyImage
		}

	};
</script>

<style scoped>
	.v-lazy-image {
		opacity: 0;
		transition: opacity 2s;
	}
	.v-lazy-image-loaded {
		opacity: 1;
	}
</style>