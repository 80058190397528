<template>
    <!-- Hero section -->
	<section class="hero-section">
		<carousel class="hero-slider" :autoplay="true" :items="1" animateOut='fadeOut'  :navText="['', '']" :dots="true">
			<div class="hs-item set-bg" data-setbg="img/slider/1.webp" >
				<div class="container">
					<h2 class="pink">איפור ושיער מקצועי</h2>
					<router-link to="/makeup-and-hair.html" class="site-btn pink">קרא עוד <i class="fa fa-angle-double-left"></i></router-link>
				</div>
				<div class="next-hs set-bg" data-setbg="img/slider/2.webp">
					<a href="#" class="nest-hs-btn" @click="nextSlide">הבא</a>
				</div>
			</div>
			<div class="hs-item set-bg" data-setbg="img/slider/2.webp">
				<div class="container">
					<h2>איפור כלות</h2>
					<router-link to="/bride.html" class="site-btn">קרא עוד <i class="fa fa-angle-double-left"></i></router-link>
				</div>
				<div class="next-hs set-bg" data-setbg="img/slider/3.webp">
					<a href="#" class="nest-hs-btn" @click="nextSlide">הבא</a>
				</div>
			</div>
			<div class="hs-item set-bg" data-setbg="img/slider/3.webp">
				<div class="container">
					<h2>איפור ערב</h2>
					<router-link to="/evening.html" class="site-btn">קרא עוד <i class="fa fa-angle-double-left"></i></router-link>
				</div>
				<div class="next-hs set-bg" data-setbg="img/slider/1.webp">
					<a href="#" class="nest-hs-btn" @click="nextSlide">הבא</a>
				</div>
			</div>
		</carousel>
		<div class="hero-social-warp">
			<p>עקבו אחריי ברשתות החברתיות&nbsp;&nbsp;</p>
			<div class="hero-social-links">
				<a href="http://tiny.cc/yaelmakeupfb"><i class="fa fa-facebook"></i></a>
				<a href="https://www.instagram.com/yaeltamam/"><i class="fa fa-instagram"></i></a>
			</div>
		</div>
	</section>
	<!-- Hero section end-->
</template>

<script>
    import carousel from 'vue-owl-carousel'

    export default {
        mounted : function() {
            /*------------------
            Background Set
            --------------------*/
            $('.set-bg').each(function() {
                var bg = $(this).data('setbg');
                $(this).css('background-image', 'url(' + bg + ')');
            });
        },
        methods: {
            nextSlide : function() {
                $(".owl-prev").click();
            }
        },
        components: { carousel }
    }
</script>

<style>
    .hero-slider {
		direction: ltr;
		display: block;
	}

	.owl-dots {
		position: absolute;
		right: 210px;
		bottom: 34px;
	}

	.owl-dots .owl-dot {
		width: 33px;
		height: 3px;
		background: #fff;
		margin-right: 7px;
	}

	.owl-dots .owl-dot.active {
		background: #ff006c;
	}

	.owl-dots .owl-dot:last-child {
		margin-right: 7px;
	}

	.owl-nav {
		position: absolute;
		right: 130px;
		bottom: 25px;
	}

	.owl-nav button.owl-next {
		margin-right: 8px;
	}

    .hero-slider .owl-theme .owl-nav .owl-next,
    .hero-slider .owl-theme .owl-nav .owl-prev {
		background: none;
		width: 29px;
		height: 29px;
		border: 3px solid #fff;
		border-radius: 50%;
		color: #fff;
		margin-right: 8px;
		position: relative;
		text-align: center;
	}

    .hero-slider .owl-nav .owl-next:after,
    .hero-slider .owl-nav .owl-prev:after {
		position: absolute;
		content: "";
		top: 7px;
		border-top: 5px solid transparent;
		border-right: 11px solid #fff;
		border-left: 0px;
		border-bottom: 5px solid transparent;
		left: 5px;
	}

    .hero-slider .owl-theme .owl-nav .owl-next:hover,
    .hero-slider .owl-theme .owl-nav .owl-prev:hover {
		border-color: #ff006c;
		color: #ff006c;
		background: none;
	}

    .hero-slider .owl-nav .owl-next:hover:after,
    .hero-slider .owl-nav .owl-prev:hover:after {
		border-right: 11px solid #ff006c;
		border-left: 0;
	}

    .hero-slider .owl-nav .owl-next {
		margin-right: 8px;
		background: none;
	}

    .hero-slider .owl-nav .owl-next:after {
		border-right: 0;
		border-left: 9px solid #fff;
		left: 8px;
	}

	.hero-slider .owl-nav .owl-next:hover:after {
		border-right: 0;
		border-left: 9px solid #ff006c;
	}

	.owl-theme .owl-dots .owl-dot :first-child {
		display:none;
	}
</style>