<template>
    <!-- Footer section -->
	<footer class="footer-section">
		<div class="about-container">
			<div class="row">
				<div class="col-lg-6 col-sm-6" id="contact">
                    <div class="footer-widget contact-widget">
						<h2 class="fw-title">צור קשר</h2>
						<ul>
							<li><span>כתובת:</span><a href="https://www.google.com/maps/place/יעל+תמם+-+איפור+כלות+ואיפור+ערב%E2%80%AD/@31.768117,34.6331323,17z/data=!3m1!4b1!4m5!3m4!1s0x1502a2649850fc61:0x257077bcc7807f31!8m2!3d31.768117!4d34.635321">אפרסמון 5 אשדוד</a></li>
							<li><span>טלפון:</span><a href="tel:+972-52-3426080">052-3426080</a></li>
							<li><span>דוא"ל:</span><a href="mailto:yaelt.makeup@gmail.com">yaelt.makeup@gmail.com</a></li>
						</ul>
						<div class="fw-social">
							<a href="tel:+972523426080"><i class="fa fa-phone"></i></a>
							<a href="https://www.instagram.com/yaeltamam/"><i class="fa fa-instagram"></i></a>
							<a href="http://tiny.cc/yaelmakeupfb"><i class="fa fa-facebook"></i></a>
							<a href="https://api.whatsapp.com/send?phone=972523426080"><i class="fa fa-whatsapp"></i></a>
							<a href="https://www.waze.com/ul?ll=31.76802240%2C34.63541000&navigate=yes"><i class="fa fa-car"></i></a>
						</div>
					</div>
                    <div class="footer-widget fw-about">
                        <img src="img/footer-logo.png" alt="">
                    </div>
				</div>
				<div class="col-lg-6 col-sm-7">
				<p>השאירו פרטים ואחזור אליכם בהקדם</p>
				<div class="comment-form">
						<div class="row">
							<div class="col-md-6">
								<input type="text" placeholder="שם" v-model="clientName">
							</div>
							<div class="col-md-6">
								<input type="text" placeholder="טלפון" v-model="clientPhone">
							</div>
							<div class="col-md-12">
								<p>סוג שירות מבוקש</p>
								<select class="col-md-12" :value="makeupType">
									<option value="איפור כלות">איפור כלות</option>
                                    <option value="איפור ערב">איפור ערב/נשף</option>
                                    <option value="איפור יום/צילומים">איפור יום/צילומים</option>
                                    <option value="איפור ושיער">איפור ושיער</option>
                                    <option value="איפור אפקטים/תחפושת/סרטים">איפור אפקטים/תחפושת/סרטים</option>
                                    <option value="סדנת איפור אישי">סדנת איפור אישי</option>
								</select>
                                <datepicker v-model="eventDate" placeholder="תאריך האירוע" :language="languages.he" format="dd/MM/yyyy"></datepicker>
                                <textarea v-model="comments" placeholder="הערות"></textarea>
                                <p>שלח באמצעות:
                                <input type="radio" name="sendMethod" value="email" v-model="sendMethod">  דוא"ל
                                <input type="radio" name="sendMethod" value="whatsapp" v-model="sendMethod" checked>WhatsApp
                                </p>
                                <button class="site-btn sb-dark" v-on:click="sendMessage">שלח <i class="fa fa-angle-double-left"></i></button>
							</div>
						</div>
                        <input type="hidden" name="to" value="leo212@gmail.com">
                        <input type="hidden" name="from" value="leo212@gmail.com">
                        <input type="hidden" name="subject" value="Hello">
                        <input type="hidden" name="text" value="Text">

                </div>
                </div>
			</div>

			<div>
				<router-link to="/ashdod.html">איפור ערב באשדוד</router-link>
                &nbsp;
				<router-link to="/purim.html">איפור פורים באשדוד</router-link>
                &nbsp;
				<router-link to="/bride.html">איפור כלות</router-link>
                &nbsp;
				<router-link to="/ashdod2.html">מאפרת באשדוד</router-link>
			</div>
			<div style="text-align:center;direction:ltr;margin-top:16px">
			<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
Copyright &copy;{{new Date().getFullYear()}} All rights reserved | This template is made with <i class="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>
<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
			</div>
		</div>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
            <a href="https://api.whatsapp.com/send?phone=972523426080" class="float" target="_blank">
            <i class="fa fa-whatsapp my-float"></i>
        </a>
	</footer>
	<!-- Footer section end -->
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import * as lang from "vuejs-datepicker/src/locale";

    export default {
        name: 'SiteFooter',
        components: {
            Datepicker
        },
        props: {
            makeupType: String
        },
        data() {
            return {
                languages: lang,
                clientName: "",
                clientPhone: "",
                eventDate: "",
                comments: "",
                sendMethod: "whatsapp"
            }
        },
        methods: {
            sendMessage: function() {
                gtag('event', 'contact', {});
                gtag('event', 'conversion', {'send_to': '1/contact','value': 1.0,'currency': 'USD'});
                let data = this._data;
                let title = this._props.makeupType;
                let dateString = "";

                if (data.eventDate !== "")
                    dateString = ` לתאריך ${data.eventDate.getDate()}/${data.eventDate.getMonth()+1}/${data.eventDate.getFullYear()}`;

                let message = "מתעניינ/ת ב"+this._props.makeupType+dateString+" "+data.comments+ " - " + data.clientName;
                if (data.sendMethod === "whatsapp")
                    window.open("https://api.whatsapp.com/send?phone=972523426080&text="+message+"&source=&data=");
                else
                    window.open('mailto:yael.t.makeup@gmail.com?subject='+title+'&body='+message+" "+data.clientPhone);
            }
        }
    }
</script>

<style>
    .about-container {
        margin-left: 15px;
        margin-right: 15px;
    }

    #contact {
        display: flex;
        justify-content: space-evenly;
    }

    .float{
        position:fixed;
        width:60px;
        height:60px;
        bottom:20px;
        left:20px;
        background-color:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        font-size:30px;
        box-shadow: 2px 2px 3px #999;
        z-index:100;
    }

    .my-float{
        margin-top:16px;
    }
</style>